import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Service Provider`}</p>
    <p>{`ERPNext for Services
ERPNext is ideal for service organizations with a integrated projects and people management.`}</p>
    <p>{`Try ERPNext free for 14 days`}</p>
    <p>{`Project Management
Manage all your projects with tasks, assignments and reminders and tracking expenses and billing.
Billing and Payments
Track all things financial, including billings and payments with the ERPNext accounts module.
HR and Payroll
Manage your employee data, payroll, leave and attendance, expense claims, all within your ERPNext system.
Time Tracking
ERPNext helps you maintain timesheets against projects or tasks and also update them in bulk.
CRM and Sales
Track and follow up with leads and opportunites and send out rule based emails and newsletters.
Helpdesk
Manage your customer service and support with email integrated issue tracker.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      